// These imports have no CSS output, but provide variables, mixins and helpers to aid in construction of
// a design cutup.

@import "../imports/functions.scss";
@import "../imports/mixins.scss";
@import "../imports/variables.scss";
@import "../imports/placeholders.scss";

.qld__banner_contained {

    @media (min-width: 992px) {
        
        .qld__banner .qld__banner__content {
            max-width: 46rem;
        }
        
        /*sets the search in the banner */
        .qld__banner.qld__banner--search .qld__banner__content {
            max-width: 30%;
        }

        .qld__header .qld__header__main.qld__header__main--dark-alt .qld__header__search{
            background-color: transparent;
        }

        .qld__header .qld__header__main.qld__header__main--dark .qld__header__search{
            background-color: transparent;
        }

        .qld__header .qld__header__search {
            display: inline-block;
            width: 360px;
            position: relative;
            background-color: transparent;
            padding: 32px 0;
        }
    }
    
    @media (min-width: 1312px) {

        .qld__banner.qld__banner--hero-right .qld__banner__image {
            max-width: 36vw;
            width: 36vw;
            left: 5vw;
        }
        
        .qld__banner .qld__banner__content {
            width: 100%;
        }
        
        .content {
            position: relative;
            /*Needed so content sits properly on the stack*/
        }
        
        #content {
            position: relative;
            /*Needed so content sits properly on the stack*/
        }
        
        .qld__main-nav {
            max-width: 1600px;
            /*max size for the navigation component*/
            margin: auto;
            /*keeps nav in center*/
        }
        
        .qld__banner {
            max-width: 1600px;
            /*max size for the banner component*/
            margin: auto;
            /*keeps banner in center*/
            box-shadow: 0px 1px 2px rgb(17 17 17 / 30%);
            /* Adds shadow to banner */
        }
        
        .qld__banner__main {
            align-content: flex-start;
        }
        
        .qld__footer {
            position: relative;
            /* Needed so content sits properly on the stack*/
        }
        
        .qld__main-nav.qld__main-nav--mega .qld__main-nav__menu-sub {
            z-index: 2; /*These shoudl be variables
            Needed so content sits properly on the stack*/
        }
        
        
        .qld__banner.qld__banner--hero-right .qld__banner__image {
            max-width: 45vw;
            width: 45vw;
        }
    }

/* Applies banner design at 1600px */

    @media (min-width: 1600px) {
        
        .qld__banner.qld__banner--hero-right .qld__banner__image {
        max-width: 672px;
        left: 1.5rem;
        right: 0;
        }
        
        /* Applies the cured style for main nav */
        .qld__main-nav {
        border-radius: 32px 32px 0px 0px;
        /* Curved edges for nav*/
        box-shadow: 0px 1px 2px hsl(37deg 14% 67%);
        position: relative;
        }
    
        /* Applies the cured style for banner */
        .qld__banner {
        border-radius: 0px 0px 40px 40px;
        /* Curved edges for banner*/
        z-index: 1;
        position: relative;
        }
        
        .qld__body--breadcrumb {
            padding-top: 32px;
            max-width: 100rem;
            margin: 0 auto;
            border-radius: 0 0 48px 48px;
            box-shadow: 0px 1px 2px rgba(0,0,0,.3), 0px 2px 6px 2px rgba(0,0,0,.15);
            position: relative;
            z-index: 1;
        }
    
        /*  This applies a background to the whole top of the header */
        .qld__header {
            position: relative;
            overflow: hidden;
        }

        .qld__header__contained-banner {
            position: absolute;
            width: 100%;
            content: " ";
            background-color: var(--QLD-color-contained-layout__background);
            background-size: contain;
            background-position: top left;
            background-repeat: no-repeat;
            background-size: 60%;
            height: 800px;
            
        }

        .qld__header {
            background: transparent;
        }
        
        .qld__header__main {
            background: transparent;
            /* removes current header background */
            position: relative;

            &.qld__header__main--dark, &.qld__header__main--dark-alt {
                background: transparent;
            }
        }
        /* Adds height and pulls the div below the banner to sit under it slightly */
    
        main.landing #content section:first-of-type:not(.qld__tab-section) { 
            padding-top: 8rem;
            margin-top: -4rem;
        }
        
        
        main.content .qld__banner + div.qld__body, main.content .qld__body--breadcrumb + div.qld__body, main.content .qld__body--breadcrumb + section.qld__body{ 
            padding-top: 8rem; 
            margin-top: -4rem;
            
        }
        
        main.site_search section.qld__search { 
            padding-top: 8rem; 
            margin-top: -4rem;
            position: relative;       
        }

        .qld__tab-section {
            margin-top: 96px;
        }

        .qld__banner.qld__banner--alt+#content > section:first-child,
        .qld__banner.qld__banner--alt+#content > data:first-child + section {

            &.qld__tab-section{
                box-shadow: 0 -148px 0 0 var(--QLD-color-light-alt-background);
            } 
        }

        .qld__banner.qld__banner--dark+#content > section:first-child,
        .qld__banner.qld__banner--dark+#content > data:first-child + section {

            &.qld__tab-section{
                box-shadow: 0 -148px 0 0 var(--QLD-color-dark-background);
            }  
        }
        
        .qld__banner.qld__banner--dark-alt+#content > section:first-child,
        .qld__banner.qld__banner--dark-alt+#content > data:first-child + section {

            &.qld__tab-section{
                box-shadow: 0 -148px 0 0 var(--QLD-color-dark-alt-background);
            }    
        }

        .qld__body--alt+.qld__tab-section,
        .qld__body--alt+data+.qld__tab-section {
            box-shadow: 0 -148px 0 0 var(--QLD-color-light-alt-background);
        }

        .qld__body--dark+.qld__tab-section,
        .qld__body--dark+data+.qld__tab-section {
            box-shadow: 0 -148px 0 0 var(--QLD-color-dark-background);
        }

        .qld__body--dark-alt+.qld__tab-section,
        .qld__body--dark-alt+data+.qld__tab-section {
            box-shadow: 0 -148px 0 0 var(--QLD-color-dark-alt-background);
        }
    
    }
}